import {Component, ViewChild, ElementRef} from '@angular/core';
import {IgxCalendarComponent, IgxDialogComponent} from 'igniteui-angular';
import {CalendarioService} from '../service/calendario.service';
import { ModalService } from '../service/modal.service';

@Component({selector: 'app-calendario', styleUrls: ['./app-calendario.component.scss'], templateUrl: './app-calendario.component.html'})

export class AppCalendarioComponent {

    @ViewChild('calendar', {static: true})public calendar: IgxCalendarComponent;

    constructor(private calendarioService: CalendarioService,
                private modalService: ModalService ) {}

    public dados: any;

    public onSelection(data: Date) {
      this.calendarioService.getImpostos(data).subscribe(impostos => {
        this.dados = impostos;
        this.openModal('modal-calendario');
      });
    }

    openModal(id: string) {
      this.modalService.open(id);
    }

    closeModal(id: string) {
      this.modalService.close(id);
    }

}
