import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {AppCalendarioComponent} from './app-calendario/app-calendario.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSpinnerModule} from 'ngx-spinner';
import {IgxCalendarModule, IgxDialogModule} from 'igniteui-angular';
import {InicioComponent} from './inicio/inicio.component';
import {LoginComponent} from './login/login.component';
import {HttpClientModule} from '@angular/common/http';
import {CalendarioService} from './service/calendario.service';
import {FooterComponent} from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { AreaDoClienteComponent } from './area-do-cliente/area-do-cliente.component';
import { VerMaisComponent } from './ver-mais/ver-mais.component';
import { ModalComponent } from './modal/modal.component';
import { LinksComponent } from './links/links.component';

@NgModule({
    declarations: [
        AppComponent,
        AppCalendarioComponent,
        InicioComponent,
        LoginComponent,
        FooterComponent,
        MenuComponent,
        AreaDoClienteComponent,
        VerMaisComponent,
        ModalComponent,
        LinksComponent
    ],

    imports: [
        BrowserModule,
        AppRoutingModule,
        CalendarModule.forRoot(
            {provide: DateAdapter, useFactory: adapterFactory}
        ),
        NgbModule,
        BrowserAnimationsModule,
        IgxCalendarModule,
        IgxDialogModule,
        NgxSpinnerModule,
        HttpClientModule
    ],
    providers: [CalendarioService],
    bootstrap: [AppComponent]
})
export class AppModule {}
