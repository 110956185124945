<div class="principal-body">
  <header class="h-top">
    <app-menu></app-menu>
  </header>

  <div class="caixa">

    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">

        <div class="carousel-item active">
          <img src="../../assets/principal/slide1.png" class="d-block w-100" alt="Slide 1"></div>

        <div class="carousel-item">
          <img src="../../assets/principal/slide2.png" class="d-block w-100" alt="Slide 2"></div>

        <div class="carousel-item">
          <img src="../../assets/principal/slide3.png" class="d-block w-100" alt="Slide 3"></div>

      </div>

      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>

    </div>

    <div class="caixa-p">

      <div class="row full-lp d-flex justify-content-center">
        <div class="col-md-3">

          <h3 class="links-uteis-2-title">
            Links Úteis
          </h3>

          <ul class="links-uteis">
            <li>
              <a href="http://receita.economia.gov.br/" target="_blanck">
                <img alt="Logo receita federal" class="icone" src="../../assets/principal/receita.png"></a>
            </li>
            <li>
              <a href="http://www.caixa.gov.br/Paginas/home-caixa.aspx" target="_blanck">
                <img alt="Logo caixa" class="icone" src="../../assets/principal/caixa.png"></a>
            </li>
            <li>
              <a href="http://www8.receita.fazenda.gov.br/SimplesNacional/" target="_blanck">
                <img alt="Logo simples nacional" class="icone sn" src="../../assets/principal/simplesnacio.jpg"></a>
            </li>
            <li>
              <a href="https://cfc.org.br/?acesso=1" target="_blanck">
                <img alt="Logo cfc" class="icone" src="../../assets/principal/cfc.png"></a>
            </li>
            <li>
              <a href="http://www.correios.com.br/" target="_blanck">
                <img alt="Logo correios" class="icone" src="../../assets/principal/correios.png"></a>
            </li>
            <li>
              <a href="https://www.crcmg.org.br/index/home?crcmg=home" target="_blanck">
                <img alt="Logo crcmg" class="icone crc" src="../../assets/principal/crcmg.png"></a>
            </li>
            <br />
            <br />
          </ul>

          <ul class="links-uteis-2">
            <li>
              <p> <a href="http://receita.economia.gov.br/" target="_blanck">Receita Federal</a></p>
            </li>
            <li>
              <p><a href="http://www.caixa.gov.br/Paginas/home-caixa.aspx" target="_blanck">Caixa economica</a></p>
            </li>
            <li>
              <p> <a href="http://www8.receita.fazenda.gov.br/SimplesNacional/" target="_blanck">Simples nacional</a>
              </p>
            </li>
            <li>
              <p> <a href="http://www.correios.com.br/" target="_blanck">Correios</a></p>
            </li>
            <li>
              <p> <a href="https://cfc.org.br/?acesso=1" target="_blanck">CFC</a></p>
            </li>
            <li>
              <p> <a href="https://www.crcmg.org.br/index/home?crcmg=home" target="_blanck">Crcmg</a></p>
            </li>
          </ul>

          <h3 class="agt-h3">AGENDA TRIBUTÁRIA</h3>
          <app-calendario></app-calendario>

        </div>


        <div class="col-md-7">

          <h3 class="notice-title">Notícias</h3>

          <p class="text">
            Eder , consectetur adipiscing elit. Suspendisse mauris turpis, tempus vel porttitor nec, suscipit eu libero.
            Vivamus lacinia luctus vulputate. Sed eu auctor risus, ac commodo tortor. Sed aliquam consectetur orci in
            placerat. Sed erat magna, congue sit amet arcu id, varius imperdiet ante. Praesent volutpat orci at dui
            maximus
            condimentum. Sed suscipit purus ipsum, quis ornare dui rhoncus vel. Nunc quam nisl, mollis vitae efficitur
            ut,
            <a id="dc" href="https://discord.gg/2P5yH8v" target="_blanck">discord</a>.
          </p>

          <h3 class="service">Serviços</h3>
          <div class="accordion" id="accordionExample">

            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
                    aria-expanded="false" aria-controls="collapseOne">
                    Constituição de Empresas
                    <i class="fas fa-sort-down"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">

                  <ul class="lista-g">
                    <li>Firma Individual&nbsp;</li>
                    <li>Sociedade por Cotas&nbsp;</li>
                    <li>Sociedade Anônima&nbsp;</li>
                    <li>Fundações&nbsp;</li>
                    <li>Associações&nbsp;</li>
                    <li>Cooperativas</li>
                    <li>Condomínios</li>
                  </ul>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="false" aria-controls="collapseTwo">
                    Escrituração Contábil
                    <i class="fas fa-sort-down"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">

                  <ul class="lista-g">
                    <li>Escrituração de Notas Fiscais&nbsp;</li>
                    <li>Conferência da Emissão das Notas Fiscais&nbsp;</li>
                    <li>Comunicação e Correção de Falhas Verificadas&nbsp;</li>
                    <li>Acompanhamento das Alterações</li>
                    <li>Emissão dos Livros Fiscais&nbsp;</li>
                    <li>Escrituração dos Estoques&nbsp;</li>
                    <li>Apuração dos Tributos a Recolher&nbsp;</li>
                    <li>Emissão das Guias de Recolhimento (DIRF, DCTF, DIPI, DIPJ, GIA Mod. "A", etc.)</li>
                    <li>Assessoria</li>
                  </ul>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Recursos Humanos
                    <i class="fas fa-sort-down"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                <div class="card-body">

                  <ul class="lista-g">
                    <li>Controle e Execução de Rotinas Trabalhistas</li>
                    <li>Execução da Admissão e Demissão de Empregados</li>
                    <li>Emissão de Envelopes de Pagamento de Salários</li>
                    <li>Controle de Emissão de Recibos de "Pro-labore"</li>
                    <li>Emissão de Folha de Pagamento de Salários</li>
                    <li>Emissão das Guias de Recolhimento das Contribuições sobre a Folha</li>
                    <li>Emissão de Relatórios Complementares (RAIS, GFIP, CAGED)</li>
                    <li>Controle de Contrato de Experiência</li>
                    <li>Controle do Período Aquisitivo de Férias</li>
                    <li>Acompanhamento nas Alterações Legais</li>
                    <li>Assessoria</li>
                  </ul>

                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingFour">
                <h5 class="mb-0">
                  <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                    data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Serviços Contábeis
                    <i class="fas fa-sort-down"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                <div class="card-body">

                  <ul class="lista-g">
                    <li>Planejamento Contábil&nbsp;</li>
                    <li>Elaboração de Plano de Contas&nbsp;</li>
                    <li>Escrituração&nbsp;</li>
                    <li>Elaboração de Relatórios Contábeis&nbsp;</li>
                    <li>Análise de Balanços</li>
                    <li>Perícias Contábeis&nbsp;</li>
                    <li>Auditoria</li>
                    <li>Assessoria</li>
                  </ul>

                </div>
              </div>
            </div>


            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFive"
                    aria-expanded="false" aria-controls="collapseFive">
                    Tributário Empresarial
                    <i class="fas fa-sort-down"></i>
                  </button>
                </h5>
              </div>
              <div id="collapseFive" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">

                  <ul class="lista-g">
                    <li>Planejamento Tributário</li>
                    <li>Fusão, Cisão e Incorporação de Empresas</li>
                    <li>Transformação de Tipo Jurídico</li>
                    <li>Holdings</li>
                    <li>Concordatas</li>
                    <li>Falências</li>
                    <li>Mediação em Litígios Societários</li>
                    <li>Assessoria</li>
                  </ul>

                </div>
              </div>
            </div>


          </div>

        </div>
      </div>

    </div>
  </div>
  <app-footer></app-footer>
