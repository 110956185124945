<div class="borda">
  <div class="topnav">
    <a href="#" class="hreflink img-link">
      <img class="h-img" src="../../assets/principal/logo2.png" alt="Logotipo do site"></a>
    <a class="menu-a" href="./index.html">HOME</a>
    <a class="menu-a" href="#">EMPRESA</a>
    <a class="menu-a" href="http://econeteditora.com.br/">BOLETIM</a>
    <a class="menu-a" href="#/links">Links</a>
    <a class="nav-link dropdown-toggle menu-a" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      Outros
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
      <a class="dropdown-item" href="#">Legislação</a>
      <a class="dropdown-item" href="#">Certidões</a>
      <a class="dropdown-item" href="#">Utilidades</a>
    </div>
    <button onclick="location.href='#/login';" value="Teste" class="adc">
      <i class="fas fa-user"></i>
      área do cliente
    </button>
  </div>
</div>
