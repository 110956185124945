
<div class="body">
<div lang="pt-br">
<head>
    <title>Loguin | Fase Beta </title>
    <meta charset="utf-8">
    <meta name="author" content="Combat Sembenevi">
    <meta name="description" content="coloca quando alguém pesquisa algo na barra de pesquisa">
    <meta name="keywords" content="palavras chaves ">

</head>

<header class="h-top">

    <div class="borda">

        <nav class="menu">

            <ul class="correto">
                <li> <a class="menu-a" href="#">EMPRESA</a> </li>
                <li> <a class="menu-a" href="#">BOLETIM</a> </li>
                <li> <a class="menu-a" href="#">serviços</a> </li>
                <li> <a class="menu-a" href="#">Contato</a> </li>
                <li> <a class="menu-a" href="#">Links</a> </li>
                <button onclick="location.href='/';" value="Teste" class="adc"><i class="fas fa-caret-left"></i> Voltar</button>
            </ul>
        </nav>
    </div>

    <article class="center">
      <div class="box-login">
        <img src="./assets/login/01_login.jpg" class="logo" alt="Imagem de um senhor para a tela de login do site">

        <form name="frmLogin">
            <div class="textbox">
                <p>Usuario</p>
                <i class="fas fa-user"></i>
                <input type="text" name="txtUser" placeholder="Usuario">
            </div>

            <div class="textbox">
                <p> Senha</p>
                <i class="fas fa-lock"></i>
                <input type="password" name="txtPass" placeholder="Senha">
            </div>
            <input class="entrar" type="submit" name="btnLogin" value="Entrar" style=" margin-left: 21px;">

            <div class="e-senha">
                <a href="#"> Esqueceu sua senha?</a>
                <br>
                <a href="#"> Area do administrador</a>

            </div>
        </form>
      </div>
    </article>


</header>
<app-footer class="footerc"></app-footer>
</div>

