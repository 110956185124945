import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppCalendarioComponent } from './app-calendario/app-calendario.component';
import { InicioComponent } from './inicio/inicio.component';
import { LoginComponent } from './login/login.component';
import { AreaDoClienteComponent } from './area-do-cliente/area-do-cliente.component';
import { VerMaisComponent } from './ver-mais/ver-mais.component';
import { LinksComponent } from './links/links.component';


const routes: Routes = [
  { path: '', component: InicioComponent },
  { path: 'login', component: LoginComponent},
  { path: 'logout', component: AppCalendarioComponent },
  { path: 'acesso-invalido', component: AppCalendarioComponent },
  { path: 'servico-indisponivel', component: AppCalendarioComponent },
  { path: 'area-cliente', component: AreaDoClienteComponent },
  { path: 'ver-mais', component: VerMaisComponent },
  { path: 'links', component: LinksComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
