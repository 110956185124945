import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

@Injectable()
export class CalendarioService {

    dataConsultada: string;

    constructor(private http: HttpClient) {}

    urlCalendario = 'https://cors-anywhere.herokuapp.com/https://cntdrs.com.br/menu/nova_central/agenda_tributaria/diaagenda.php?';

    getImpostos(d: Date): Observable < any[] > {
      this.dataConsultada = 'dt=' + d.getDate() + '&mes=' + this.getMesCerto(d.getMonth()) + '&ano=' + d.getFullYear();
      return this.http.get<any[]>(this.urlCalendario + this.dataConsultada,
        { headers, responseType: 'text' as 'json'}).pipe(map((data: any) => data));
    }

    getMesCerto(mes: number) {
      return mes + 1;
    }

}
