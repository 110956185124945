
<div class="body">
<header>
  <div class="borda">

    <nav class="menu">

      <ul class="correto">
        <li> <a class="menu-a" href="#">EMPRESA</a> </li>
        <li> <a class="menu-a" href="#">BOLETIM</a> </li>
        <li> <a class="menu-a" href="#">serviços</a> </li>
        <li> <a class="menu-a" href="#">Contato</a> </li>
        <button onclick="location.href='/';" value="Teste" class="adc"><i class="fas fa-caret-left"></i> Voltar</button>
      </ul>
    </nav>
  </div>
</header>

<div class="completa">

  <div class="center">

    <div class="caixa caixa-1">
      <h1>Docs.legais</h1>
      <p><i class="fa fa-folder-open" aria-hidden="true"></i></p>

      <ul class="docs-l">
        <li class="docs-v">Documento 1</li>
        <li class="docs-v">Documento 2</li>
        <li class="docs-v">Documento 3</li>
        <li class="docs-v">Documento 4</li>
        <li class="docs-v">Documento 5</li>
        <li class="docs-v">Documento 6</li>
        <li class="docs-v">Documento 7</li>
      </ul>

      <a href="#/ver-mais" class="ver-m">Ver mais</a>
    </div>

    <div class="caixa caixa-2">
      <h1>Depto.pessoal</h1>
      <p><i class="fa fa-folder-open" aria-hidden="true"></i></p>

      <ul class="docs-l">
        <li class="docs-v">Documento 1</li>
        <li class="docs-v">Documento 2</li>
        <li class="docs-v">Documento 3</li>
        <li class="docs-v">Documento 4</li>
        <li class="docs-v">Documento 5</li>
        <li class="docs-v">Documento 6</li>
        <li class="docs-v">Documento 7</li>
      </ul>

      <a href="#/ver-mais" class="ver-m">Ver mais</a>
    </div>

    <div class="caixa caixa-3">
      <h1>Fiscal</h1>
      <p><i class="fa fa-folder-open" aria-hidden="true"></i></p>

      <ul class="docs-l">
        <li class="docs-v">Documento 1</li>
        <li class="docs-v">Documento 2</li>
        <li class="docs-v">Documento 3</li>
        <li class="docs-v">Documento 4</li>
        <li class="docs-v">Documento 5</li>
        <li class="docs-v">Documento 6</li>
        <li class="docs-v">Documento 7</li>
      </ul>

      <a href="#/ver-mais" class="ver-m">Ver mais</a>
    </div>

    <div class="caixa caixa-4">
      <h1>Impostos</h1>
      <p><i class="fa fa-folder-open" aria-hidden="true"></i></p>

      <ul class="docs-l">
        <li class="docs-v">Documento 1</li>
        <li class="docs-v">Documento 2</li>
        <li class="docs-v">Documento 3</li>
        <li class="docs-v">Documento 4</li>
        <li class="docs-v">Documento 5</li>
        <li class="docs-v">Documento 6</li>
        <li class="docs-v">Documento 7</li>
      </ul>

      <a href="#/ver-mais" class="ver-m">Ver mais</a>
    </div>

  </div>

</div>

<app-footer class="footer"></app-footer>

</div>
