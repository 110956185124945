<footer class="footer-distributed ">
  <div class="row align-items-start">

    <div class="col-md-5">

    </div>

    <div class="col-md-4 logo-f">
      <img class="f-img" src="../../assets/principal/logo2.png" alt="Logo do AEMC">
      <p> © Todos os direitos reservados</p>
    </div>

    <div class="col-md-2 contato">
      <p>
        <i class="fas fa-map-marker-alt"></i>
        <span>
          Av.Bias Fortes, 943<br><b>Barbacena/MG</b>
        </span>
      </p>
      <p>
        <i class="fas fa-phone-alt"></i>
        <span>(32) 3051-0305</span>
      </p>
      <p>
        <i class="fas fa-envelope"></i>
        <span>amec_org@hotmail.com</span>
      </p>
    </div>

  </div>
</footer>
