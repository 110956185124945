<div class="body">

<header>
  <div class="borda">

    <nav class="menu">

      <ul class="correto">

        <form class="form-inline my-2 my-lg-0">
          <input class="form-control mr-sm-2" type="search" placeholder="Pesquise aqui..." aria-label="Search">
          <button class="btn btn-outline-success my-2 my-sm-0" type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
        </form>

        <button onclick="location.href='/area-cliente';" value="Teste" class="adc"><i class="fas fa-caret-left"></i> Voltar</button>

      </ul>
    </nav>
  </div>
</header>

<div class="center">

  <ul>
    <li class="caixas">
      <div class="text"><i class="fa fa-folder" aria-hidden="true"></i>Documento Documento Documento Documento</div>
      <div class="center-b"><button><i class="fas fa-download"></i></button></div>
    </li>
        <li class="caixas">
      <div class="text"><i class="fa fa-folder" aria-hidden="true"></i>Documento Documento Documento Documento</div>
      <div class="center-b"><button><i class="fas fa-download"></i></button></div>
    </li>
        <li class="caixas">
      <div class="text"><i class="fa fa-folder" aria-hidden="true"></i>Documento Documento Documento Documento</div>
      <div class="center-b"><button><i class="fas fa-download"></i></button></div>
    </li>
        <li class="caixas">
      <div class="text"><i class="fa fa-folder" aria-hidden="true"></i>Documento Documento Documento Documento</div>
      <div class="center-b"><button><i class="fas fa-download"></i></button></div>
    </li>
        <li class="caixas">
      <div class="text"><i class="fa fa-folder" aria-hidden="true"></i>Documento Documento Documento Documento</div>
      <div class="center-b"><button><i class="fas fa-download"></i></button></div>
    </li>
        <li class="caixas">
      <div class="text"><i class="fa fa-folder" aria-hidden="true"></i>Documento Documento Documento Documento</div>
      <div class="center-b"><button><i class="fas fa-download"></i></button></div>
    </li>
        <li class="caixas">
      <div class="text"><i class="fa fa-folder" aria-hidden="true"></i>Documento Documento Documento Documento</div>
      <div class="center-b"><button><i class="fas fa-download"></i></button></div>
    </li>

  </ul>
</div>

</div>
<app-footer></app-footer>
