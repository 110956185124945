import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-area-do-cliente',
  templateUrl: './area-do-cliente.component.html',
  styleUrls: ['./area-do-cliente.component.css']
})
export class AreaDoClienteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
